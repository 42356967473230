@import '../lib/core/sass/bp'

.swiper-container
  height: 100%
  width: 100%

  .swiper-slide
    overflow: hidden


  .swiper-pagination
    // background-color: white
    padding: 10px 8px
    border-radius: 5px
    line-height: 0
    font-size: 0

  &.boxed
    .swiper-pagination
      bottom: -32px !important
      +bpw(lg)
        bottom: -42px !important
      transform: translateX(-50%) translateY(-50%)
    .swiper-button-prev,
    .swiper-button-next
      top: auto !important
      bottom: -32px !important
      +bpw(lg)
        bottom: -42px !important
      background-color: transparent
      svg
        path, polygon
          fill: $color-yellow
      &:hover
        background-color: transparent
        svg
          path, polygon
            fill: black

  .swiper-pagination,
  .swiper-pagination-clickable,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets
    width: auto !important
    left: 50% !important
    transform: translateX(-50%)
    bottom: 20px !important

  &.boxed .swiper-pagination .swiper-pagination-bullet-active,
  .swiper-pagination .swiper-pagination-bullet-active
    background: $color-yellow !important
    width: 16px

  &.boxed .swiper-pagination-bullet,
  .swiper-pagination-bullet
    width: 8px
    height: 8px
    text-align: center
    color: white
    opacity: 1
    background: $color-grey-light
    border-radius: 8px
    margin: 0 3px
    transition: width .25s ease-in-out

    .swiper-pagination-bullet-active
      color: #ffffff
      background: $color-yellow

  .iscolored &
    .swiper-pagination-bullet,
    .swiper-pagination-bullet
      background: white

  .swiper-button-prev,
  .swiper-button-next
    cursor: pointer
    background-color: $color-yellow
    width: 22px
    height: 22px
    padding: 3px
    border-radius: 0px
    transition: all 0.75s
    display: flex
    top: 50%
    fill: white
    margin: 0
    transform: translateY(-50%)
    &:after
      content: ''
    &:hover
      background-color: white
      svg
        path, polygon
          fill: $color-yellow
    svg
      position: absolute
      left: 3px
      top: 3px
      width: 16px
      height: 16px
      fill: white
      +bpw(lg)
        left: 5px
        top: 5px
    +bpw(lg)
      width: 26px
      height: 26px

  .swiper-button-prev
    transform: translateY(-50%) rotate(180deg)

  .swiper-button-disabled
    opacity: 0 !important
