
@use '../../../lib/core/sass/bp' as *

$blue: #1278ef
$primary: #343434
$hairlineColor: #e6e6e6
$padding: 18px

.cf-consent-manager
  color: $primary
  font-size: 14px
  overflow: hidden
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1000
  pointer-events: none

::v-deep .cf-scrollbar
  width: 100%

.root
  position: absolute
  bottom: 0
  max-width: 640px
  left: 0
  right: 0
  margin: 0 auto
  background: white
  box-shadow: 0 0 2px 1px rgba(black, 0.15), 0 0 44px 20px rgba(black, 0.1)
  border-radius: 8px 8px 0 0
  display: flex
  overflow: hidden
  flex-direction: column
  max-height: calc(100% - 50px)
  transition: box-shadow .2s ease
  pointer-events: all

.backdrop
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(black, .4)
  pointer-events: none
  opacity: 0
  backdrop-filter: blur(24px)
  transition: opacity .3s ease

  .show-details &
    opacity: 1
    pointer-events: all

a
  color: $blue

button
  appearance: none
  border: 0
  background: none
  padding: 0
  cursor: pointer

  &:active
    transform: translateY(1px)

.consent
  padding: $padding
  background: white
  border-bottom: 1px solid $hairlineColor
  box-shadow: 0 1px 4px rgba(black, 0.05), 0 4px 24px rgba(black, 0.04)
  z-index: 2

  > header
    display: flex
    align-items: baseline

    h1
      margin: 0.2em 0 0
      font-size: 12px
      text-transform: uppercase
      letter-spacing: 0.08em

.intro
  line-height: 1.3

.actions
  display: flex
  align-items: baseline
  margin-top: .5em
  flex-wrap: wrap
  justify-content: center

  +bpw('sm')
    flex-wrap: nowrap
    justify-content: normal

  > button
    color: $blue
    flex: 0 0 auto
    padding: 10px 0

    .is-editing &
      flex: 1 0 auto
      margin-right: 0

    +bpw('sm')
      padding: 0
      flex: 0 1 auto !important
      margin-right: 1.5em !important

  .btn-agree
    background: $blue
    border-radius: 6px
    color: white !important
    transition: transform .14s ease, background .14s ease
    margin-left: 1.5em
    margin-top: .4em
    margin-right: 0 !important
    font-weight: bold
    flex: 1 0 auto

    .is-editing &
      margin-left: auto

    +bpw('sm')
      flex: 0 0 auto
      padding: 8px 12px
      margin-left: auto

      &:hover
        transform: scale(1.05)
        background: lighten($blue, 10%)

.btn-show-details
  .show-details &
    padding: 8px 12px
    border-radius: 6px

    &:after
      content: "\00a0▼"

.details-text
  flex: 1 1 auto
  background: #f8f8f8
  display: flex
  min-height: 0

.details-text-padding
  padding: $padding

.btn-close
  margin-left: auto
  font-size: 24px
  line-height: 0
  width: 14px
  height: 14px
  position: relative

  &:after
    // larger click area
    content: ""
    position: absolute
    top: -15px
    left: -15px
    bottom: -15px
    right: -15px

.consent-checkbox
  margin-right: .3em

.consent-list
  color: #707070
  margin: 0 1em 0 0
  padding: 0

  > li
    display: inline-block
    margin-right: 1em
