
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

h1
  color: $color-grey-warm-dark
  font-size: 16px
  line-height: 20px
  margin-bottom: 16px
  margin-top: 16px
p
  margin-bottom: 16px
  color: $color-grey-warm-dark
  &::v-deep
    strong
      font-family: $font-main
      text-transform: uppercase
ul
  padding: 0 16px
  font-family: $font-secondary
  font-weight: 400
  line-height: 28px
  font-size: 16px
  color: $color-bronze
  margin-bottom: 16px

