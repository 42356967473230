
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  display: block
  margin: 0
  width: 100%
  height: 70vH
  +bpw(lg)
    height: 100vH
  background-color: transparent
  overflow: auto
  box-sizing: border-box

.inner
  position: absolute
  border-radius: 8px
  left: 8px
  top: 8px
  bottom: 8px
  right: 8px
  overflow: hidden
  background-color: white

