
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

.cw-modal
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 1000
.background
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  background-color: rgba($color-grey-dark, 0.95)
  cursor: pointer
.close
  position: absolute
  top: 16px
  right: 16px
  z-index: 1000
  button
    cursor: pointer
    background-color: $color-red
    width: 22px
    height: 22px
    padding: 3px
    border-radius: 3px
    transition: all 0.75s
    display: flex
    top: 50%
    fill: white
    &:after
      content: ''
    &:hover
      background-color: white
      svg
        path, polygon
          fill: $color-red
    svg
      position: absolute
      left: 3px
      top: 3px
      width: 16px
      height: 16px
      fill: white
      +bpw(lg)
        left: 5px
        top: 5px
    +bpw(lg)
      width: 26px
      height: 26px

