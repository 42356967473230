
@use '../../sass/variables' as *
@import '~simplebar/dist/simplebar.min.css'

.cf-scrollbar
  position: relative

.simplebar-vertical
  width: 16px

  .simplebar-scrollbar
    &::before
      left: 10px
      right: 2px
      transition: left .1s ease-out, opacity .25s ease-out

      .hide-rails &
        opacity: 0 !important

  &.simplebar-hover
    .simplebar-scrollbar::before
      left: 2px

.simplebar-horizontal
  height: 16px

  .simplebar-scrollbar
    top: 0
    bottom: 0
    min-height: auto
    height: auto

    &::before
      height: auto
      top: 10px
      bottom: 2px
      transition: top .1s ease-out, opacity .25s ease-out

      .hide-rails &
        opacity: 0 !important

  &.simplebar-hover
    .simplebar-scrollbar::before
      top: 2px

.indicator
  position: absolute
  opacity: 0
  transition: opacity $animation-duration-medium ease-out

  &.y-start
    top: 0
    left: 0
    right: 0

  &.y-end
    bottom: 0
    left: 0
    right: 0

  &.x-start
    left: 0
    top: 0
    bottom: 0

  &.x-end
    right: 0
    top: 0
    bottom: 0

  &.visible
    opacity: 1
