
.cf-hamburger
  display: block
  position: relative

  width: 30px
  height: 20px

  .wrapper
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none
    transition: all .3s ease-out

    span
      position: absolute
      display: block
      transition: all .3s ease-out
      width: 100%
      left: 0

  .top-line
    top: 0

  .center-line
    top: 50%
    transform: translateY(-50%)

  .bottom-line
    bottom: 0

  .wrapper.close
    transform: rotate(135deg)

    .top-line
      top: 50%
      transform: translateY(-50%) rotate(90deg)

    .center-line
      opacity: 0

    .bottom-line
      bottom: 50%
      transform: translateY(50%)

  .wrapper.arrow-left
    transform: rotate(-180deg)

    .top-line
      width: 60%
      top: 50%
      left: 40%
      transform: rotate(45deg) translateX(0%) translateY(-50%)
      transform-origin: right center

    .bottom-line
      width: 60%
      bottom: 50%
      left: 40%
      transform: rotate(-45deg) translateX(0%) translateY(50%)
      transform-origin: right center

  .wrapper.arrow-right
    transform: rotate(180deg)

    .top-line
      width: 60%
      top: 50%
      transform: rotate(-45deg) translateX(0%) translateY(-50%)
      transform-origin: left center

    .bottom-line
      width: 60%
      bottom: 50%
      transform: rotate(45deg) translateX(0%) translateY(50%)
      transform-origin: left center
