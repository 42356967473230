
.cw-picture
  font-size: 0
  line-height: 0
  opacity: 0
  transition: all 0.45s
  user-select: none

.loaded
  opacity: 1

picture, img, source
  transition: opacity 1.45s
  width: 100%
  height: auto
.fullsize,
.fullsize picture
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  source, img
    position: absolute
    left: 50%
    width: auto
    height: 100%
    transform: translateX(-50%)

.fullsize.portrait,
.fullsize.portrait picture
  source, img
    position: absolute
    left: 0%
    top: 50%
    width: 100%
    height: auto
    transform: translateY(-50%)
