
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

.fade-enter-active,
.fade-leave-active
  transition: opacity .5s ease
.fade-enter,
.fade-leave-active
    opacity: 0

.cf-consent-manager
  &::v-deep
    .btn-agree
      text-align: center
    .intro
      font-size: 13px
      +bpw('xl')
        font-size: 15px
    header h1
      display: none
      color: $color-grey-dark
      line-height: 24px
    button
      font-family: $font-main
      text-transform: uppercase
    .consent-list
      margin-top: 12px
    label
      font-family: $font-main
      text-transform: uppercase
      font-weight: 600
