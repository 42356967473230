
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

footer
  position: relative
  display: block
  height: 320px
  +bpw(lg)
    height: 250px
  background-color: $color-grey-light

.inner
  margin: 16px
  margin-top: 32px
  flex-direction: column
  +bpw(lg)
    margin: 32px
    flex-direction: row
  +bpw(xl)
    margin: 64px
  +bpw(hg)
    margin: 128px
  display: flex
  flex: 1
  font-size: 15px
  font-family: $font-main
.developer
  +bpw(lg)
    width: 80%
  color: #28282A
  line-height: 22px
  margin-bottom: 22px
  .author
    font-size: 18px
    line-height: 26px
    &::v-deep
      a,
      a:hover,
      a:focus,
      a:visited
        color: #28282A
        text-decoration: none
  .label
    margin-bottom: 12px
    font-weight: bold
.links
  +bpw(lg)
    width: 20%
  a
    display: inline-block
    color: #28282A
    line-height: 30px
    text-decoration: none
    transition: all 0.45s ease-out
    position: relative
    &:after
      content: ''
      width: 0%
      height: 1px
      position: absolute
      left: 50%
      bottom: 2px
      background-color: $color-red
      transition: all 0.45s ease-out
    &:before
      content: ''
      width: 100%
      height: 1px
      position: absolute
      left: 0
      bottom: 2px
      background-color: rgba(white, 0.5)
    &:hover, &.active
      color: $color-red
      &:after
        width: 100%
        left: 0%
