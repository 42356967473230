
@import '../../lib/core/sass/bp'

.cw-logo
  cursor: pointer
  svg
    position: relative
    width: 100%
    height: 100%

