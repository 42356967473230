
@import '../../lib/core/sass/bp'

header
  position: fixed
  left: 0
  right: 0
  width: 100%
  z-index: 100

.wrapper-logo
  position: absolute
  top: 25px
  left: 25px
  width: 180px
  height: 62px
  +bpw('lg')
    top: 55px
    left: 75px
    width: 199px
    height: 66px
