/// Styles to reset/normalize user agent button styles
@mixin button-reset
  border: none
  margin: 0
  padding: 0
  width: auto
  overflow: visible

  background: transparent

  text-align: inherit

  // inherit font & color from ancestor
  color: inherit
  font: inherit

  // Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+.
  line-height: normal

  // Corrects font smoothing for webkit
  -webkit-font-smoothing: inherit
  -moz-osx-font-smoothing: inherit

  // Corrects inability to style clickable `input` types in iOS
  -webkit-appearance: none

  &::-moz-focus-inner
    border: 0
    padding: 0
