
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

.cw-cta
  position: fixed
  background-color: white
  bottom: 0
  right: 0
  width: auto
  text-align: left
  border-top-left-radius: 8px
  box-shadow: 0 0 12px rgba(0, 0, 0, .10)
  padding: 4px 12px 12px
  +bpw(lg)
    padding: 10px 24px 16px

  a
    display: block
    margin-bottom: 2px
    &.smallextern
      color: $color-green
      margin-top: 10px
      text-decoration: none
      transition: all 0.5s ease-out
      &:hover
        color: $color-lila
        text-decoration: underline

  p
    padding: 0
    margin: 0
    font-family: $font-secondary
    font-size: 13px
    margin-bottom: 6px
    +bpw(lg)
      font-size: 14px

  &::v-deep
    a.icon span.svg
      padding: 0
      line-height: 0
      font-size: 0

    a.icon svg
      width: 13px
      height: 28px

    a.icon > span
      padding: 0 12px
      line-height: 28px

    a.icon > span > span
      font-size: 12px

    +bpw(lg)
      a.icon svg
        width: 16px
        height: 14px
      a.icon > span
        line-height: 36px
        padding: 0 14px
      a.icon > span > span
        font-size: inherit

