
.cf-scroll-indicator
  position: relative
  pointer-events: none

.shadow
  position: absolute
  overflow: hidden

  &::before
    pointer-events: none
    position: absolute
    content: ''
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, .85) 0%, rgba(0, 0, 0, 0) 70%)

  &.down,
  &.up
    height: 20px
    width: 100%

    &::before
      width: 150%
      height: 200%
      left: 50%
      transform: translateX(-50%)

  &.left,
  &.right
    width: 20px
    height: 100%

    &::before
      height: 150%
      width: 200%
      top: 50%
      transform: translateY(-50%)

  &.down
    &::before
      bottom: 50%

  &.up
    bottom: -100%

    &::before
      top: 50%

  &.right
    &::before
      right: 50%

  &.left
    right: -100%

    &::before
      left: 50%
