
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

a, button
  cursor: pointer
  display: inline-block
  vertical-align: middle
  text-decoration: none
  color: black
  font-family: $font-main
  font-weight: 600
  overflow: hidden
  position: relative
  background-color: $color-grey-warm-dark
  box-shadow: 0 0 10px rgba($color-bronze-dark, 0)
  box-sizing: border-box
  border-radius: 4px
  fill: #ffffff
  transition: all 0.45s
  &:focus
    outline: none
    box-shadow: 0 0 5px rgba($color-yellow, 0.75)
  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    right: 100%
    height: 100%
    background-color: $color-yellow
    transition: right 0.45s
  > span
    position: relative
    display: block
    line-height: 36px
    padding: 0 14px
    text-transform: uppercase
    transition: color 0.45s
    > span
      display: inline-block
      vertical-align: middle
    svg
      polygon
        transition: fill 0.45s
  &:hover
    box-shadow: 0 0 10px rgba($color-grey-warm-dark, 0.45)
    &:before
      right: 0
  &.hasHoverColor
    &:before
      background-color: $color-lila
  &.inline
    &:hover
      box-shadow: 0 0 10px rgba($color-grey-warm-dark, 0)
      svg
        fill: $color-yellow
      > span
        color: $color-yellow
    &:before
      background-color: transparent
    > span
      padding-left: 0px !important
  &.outline
    &:hover
      svg
        polygon
          transition: fill 0.45s
          fill: white !important
      > span
        color: white
  &.icon
    span > span:first-child
      margin-right: 8px
      display: inline-block
      vertical-align: middle
    svg
      width: 16px
      height: 14px
  &.small
    > span
      line-height: 26px
      padding: 0 12px
      font-size: 14px
    &.icon
      > span
        padding-left: 6px
      span > span:first-child
        margin-right: 8px
  &.inactive
    &:hover
      &:before
        right: 100%
